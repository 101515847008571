import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PolicyInfo from "../components/Policy/PolicyInfo"
// import BannerSection from "../components/Global/TopBanner"

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="Privacy Policy" />
    {/* <BannerSection
      img={data.img.childImageSharp.fluid}
      title="Privacy Policy"
    /> */}
    <PolicyInfo />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "home-banner.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 1920
          quality: 100
          duotone: { highlight: "#071730", shadow: "#071730", opacity: 1 }
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default NotFoundPage
